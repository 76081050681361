div.details-header {
   align-items: center;
   display: flex;
   font-size: 2em;
   justify-content: space-between;
   padding: 1%;
}

div.details-name {
   min-width: 25%;
}

button.confirmation {
   align-items: center;
   background-color: deepskyblue;
   margin-left: 10px;
}