.date-header {
    white-space: nowrap;
}
.time-header {
    width: 112px;
}

th.timeline-hour {
    background-color: beige;
    padding: 5px;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    vertical-align: bottom;
    z-index: 2;
}

tr.timeline {
    vertical-align: bottom;
}
