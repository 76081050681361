td.location-column {
    background-color: beige;
    padding: 5px;
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    vertical-align: bottom;
    z-index: 1;
}

.location-link {
    color: grey;
    text-decoration: none;
    writing-mode: vertical-rl;
}

td.location-cell {
    padding: 2px;
}

ul.location-hour {
    width: 112px;
    background-color: white;
    border-width: 3px;
    border-radius: 10px;
    margin: auto;
    overflow: hidden;
}

li.location-data {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    border-radius: 10;
    border-width: 0;
}