div.container {
    background-color: beige;
    height: 100vh;
    margin: auto;
    overflow: scroll;
    width: 100vw;
}

button.add-location {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    background-color: deepskyblue;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
