p.criteria-header {
    font-size: 1.5em;
}

p.criteria-label {
    text-align: right;
}

li.criteria {
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    flex-direction: row;
    gap: 5%;
}

div.criteria-track {
    height: 4px;
    width: 75%;
}

div.criteria-thumb {
    background-color: blue;
    border-radius: 50%;
    color: white;
    height: 25px;
    text-align: center;
    width: 25px;
}